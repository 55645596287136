import { Button } from "@material-ui/core";
import React, { useRef, useEffect, useState } from "react";

const LS_NAME = 'audioMessageRate';

const Audio = ({url}) => {
    const audioRef = useRef(null);
    const [audioRate, setAudioRate] = useState(parseFloat(localStorage.getItem(LS_NAME) || "1"));
    const [showButtonRate, setShowButtonRate] = useState(false);
    const [canPlay, setCanPlay] = useState(false);

    useEffect(() => {
        const audio = audioRef.current;
        
        audio.addEventListener('loadedmetadata', () => {
            setCanPlay(true);
        });

        audio.addEventListener('playing', () => {
            setShowButtonRate(true);
        });

        audio.addEventListener('pause', () => {
            setShowButtonRate(false);
        });

        audio.addEventListener('ended', () => {
            setShowButtonRate(false);
        });

        audio.playbackRate = audioRate;
        
        return () => {
            audio.removeEventListener('loadedmetadata', () => setCanPlay(true));
            audio.removeEventListener('playing', () => setShowButtonRate(true));
            audio.removeEventListener('pause', () => setShowButtonRate(false)); 
            audio.removeEventListener('ended', () => setShowButtonRate(false));
        };
    }, [audioRate]);

    const toggleRate = () => {
        let newRate = 1;
        if (audioRate === 0.5) newRate = 1;
        else if (audioRate === 1) newRate = 1.5;
        else if (audioRate === 1.5) newRate = 2;
        else if (audioRate === 2) newRate = 0.5;

        setAudioRate(newRate);
        localStorage.setItem(LS_NAME, newRate);
        audioRef.current.playbackRate = newRate;
    };

    return (
        <>
            <audio 
                ref={audioRef}
                controls
                preload="metadata"
                playsInline 
            >
                <source src={url} type="audio/mp3" />
            </audio>
            {showButtonRate && canPlay && (
                <Button 
                    style={{marginLeft: "5px", marginTop: "-45px"}}
                    onClick={toggleRate}
                >
                    {audioRate}x
                </Button>
            )}
        </>
    );
}

export default Audio;
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  smallAvatar: {
    width: '26px',
    height: '26px',
    fontSize: '12px',
    backgroundColor: theme.palette.primary.main,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center'
  },
}));

const UserAvatarSmall = ({ user, className = '' }) => {
  const classes = useStyles();

  const getInitials = (name) => {
    if (!name) return '';
    return name
      .split(' ')
      .map(part => part[0])
      .slice(0, 2)
      .join('')
      .toUpperCase();
  };

  return (
    <Avatar 
      alt={user?.name || ""}
      className={`${classes.smallAvatar} ${className}`}
    >
      {getInitials(user.name)}
    </Avatar>
  );
};

UserAvatarSmall.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  className: PropTypes.string,
};

export default UserAvatarSmall;
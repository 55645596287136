import React, { useContext, useEffect, useState } from "react";
import {
    Badge,
    Button,
    FormControlLabel,
    makeStyles,
    Paper,
    Switch,
    Tab,
    Tabs,
    InputAdornment,
    TextField,
    Tooltip
} from "@material-ui/core";
import {
    Archive,
    HourglassMedium,
    Cards,
    MagnifyingGlass,
    UsersFour,
    User
} from "phosphor-react";
import { Can } from "../Can";
import NewTicketModal from "../NewTicketModal";
import TabPanel from "../TabPanel";
import TicketsList from "../TicketsList";
import TicketsQueueSelect from "../TicketsQueueSelect";
import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
    ticketsWrapper: {
        position: "relative",
        display: "flex",
        height: "100%",
        flexDirection: "column",
        overflowY: "hidden",
        backgroundColor: theme.palette.background.default,
        borderRadius: theme.shape.borderRadius * 2,
    },

    tabsHeader: {
        flex: "none",
        margin: theme.spacing(0, 1, 1),
        backgroundColor: "transparent",
    },

    tabsContainer: {
        minHeight: 70,
        padding: theme.spacing(0.5),
        backgroundColor: theme.palette.background.paper,
        borderRadius: 13,
        boxShadow: "0 1px 3px rgba(0,0,0,0.12)",
    },

    tab: {
        minWidth: 100,
        minHeight: 60,
        margin: theme.spacing(0, 0.5), 
        padding: theme.spacing(0.5, 1),
        borderRadius: 8,
        fontSize: "0.875rem",
        fontWeight: 500,
        textTransform: "none",
        transition: "all 0.2s ease",
        opacity: 0.7,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        
        "&:hover": {
            opacity: 1,
            backgroundColor: theme.palette.action.hover,
        },
        
        "&.Mui-selected": {
            opacity: 1,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        }
    },

    tabIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: 26,
    },

    searchContainer: {
        margin: theme.spacing(0, 1, 1),
        borderRadius: 13,
        boxShadow: "0 1px 3px rgba(0,0,0,0.12)",
    },

    searchField: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius * 3,
        "& .MuiOutlinedInput-root": {
            borderRadius: theme.shape.borderRadius * 3,
            "& fieldset": {
                borderColor: "transparent",
            },
            "&:hover fieldset": {
                borderColor: theme.palette.action.hover,
            },
            "&.Mui-focused fieldset": {
                borderColor: theme.palette.primary.main,
            }
        }
    },

    ticketOptionsBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: theme.spacing(2),
        padding: theme.spacing(1, 1),
        margin: theme.spacing(0, 1),
        backgroundColor: theme.palette.background.paper,
        borderRadius: 13,
        boxShadow: "0 1px 3px rgba(0,0,0,0.12)",
    },

    newButton: {
        height: 36,
        padding: theme.spacing(0, 2),
        borderRadius: 13,
        textTransform: "none",
        fontWeight: 500,
        boxShadow: `0 2px 8px ${theme.palette.primary.main}30`,
        
        "&:hover": {
            boxShadow: `0 4px 12px ${theme.palette.primary.main}40`,
        }
    },

    controlsGroup: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(2), 
        margin: "0 auto", 
        flex: 1, 
        justifyContent: "center" 
    },

    switchContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: theme.spacing(0.5),
        padding: theme.spacing(0.5, 1),
        borderRadius: theme.shape.borderRadius * 2,
    },

    iconButton: {
        padding: theme.spacing(0.5),
        color: theme.palette.text.secondary,
        
        "&:hover": {
            color: theme.palette.primary.main,
        }
    },

    badge: {
        top: 4,
        transform: "scale(0.8)",
    },

    ticketlist: {
        overflowY: "true",
        flex: 1,
    },
    contentArea: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden", 
    },
}));

const TicketsManager = () => {
    const classes = useStyles();
    const [searchParam, setSearchParam] = useState("");
    const [tab, setTab] = useState("open");
    const [tabOpen] = useState("open");
    const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
    const [showAllTickets, setShowAllTickets] = useState(false);
    const { user } = useContext(AuthContext);
    const [openCount, setOpenCount] = useState(0);
    const [pendingCount, setPendingCount] = useState(0);
    const userQueueIds = user.queues.map((q) => q.id);
    const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);

    useEffect(() => {
        if (user.profile.toUpperCase() === "ADMIN") {
            setShowAllTickets(true);
        }
    }, [user.profile]);

    const handleSearch = (e) => {
        const searchedTerm = e.target.value.toLowerCase();
        setSearchParam(searchedTerm);
        if (searchedTerm === "") {
            setTab("open");
        } else if (tab !== "search") {
            setTab("search");
        }
    };

    const handleChangeTab = (e, newValue) => {
        setTab(newValue);
    };

    const applyPanelStyle = (status) => {
        if (tabOpen !== status) {
            return { width: 0, height: 0 };
        }
    };

    return (
        <Paper elevation={0} className={classes.ticketsWrapper}>
            <NewTicketModal
                modalOpen={newTicketModalOpen}
                onClose={() => setNewTicketModalOpen(false)}
            />
            
            <Paper elevation={0} className={classes.tabsHeader}>
                <Tabs
                    value={tab}
                    onChange={handleChangeTab}
                    variant="fullWidth"
                    className={classes.tabsContainer}
                    TabIndicatorProps={{ style: { display: 'none' } }}
                >
                    <Tab
                        value="open"
                        icon={
                            <Badge
                                className={classes.badge}
                                badgeContent={openCount}
                                max={999}
                                color="secondary"
                            >
                                <Cards size={22} weight="light" className={classes.tabIcon} />
                            </Badge>
                        }
                        label={i18n.t("tickets.tabs.open.title").toUpperCase()}
                        className={classes.tab}
                    />
                    <Tab
                        value="pending"
                        icon={
                            <Badge
                                className={classes.badge}
                                badgeContent={pendingCount}
                                max={999}
                                color="secondary"
                            >
                                <HourglassMedium size={22} weight="light" className={classes.tabIcon} />
                            </Badge>
                        }
                        label={i18n.t("ticketsList.pendingHeader").toUpperCase()}
                        className={classes.tab}
                    />
                    <Tab
                        value="closed"
                        icon={
                            <Badge
                                className={classes.badge}
                                badgeContent={0} 
                                invisible={true} // tentativa de alinhar o icone com as outras tabs
                            >
                                <Archive size={22} weight="light" className={classes.tabIcon} />
                            </Badge>
                        }
                        label={i18n.t("tickets.tabs.closed.title").toUpperCase()}
                        className={classes.tab}
                    />
                </Tabs>
            </Paper>

            <div className={classes.searchContainer}>
                <TextField
                    placeholder={i18n.t("tickets.search.placeholder")}
                    variant="outlined"
                    fullWidth
                    value={searchParam}
                    onChange={handleSearch}
                    className={classes.searchField}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MagnifyingGlass size={20} />
                            </InputAdornment>
                        ),
                    }}
                />
            </div>

            <div className={classes.ticketOptionsBox}>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.newButton}
                    onClick={() => setNewTicketModalOpen(true)}
                >
                    {i18n.t("ticketsManager.buttons.newTicket")}
                </Button>

                <div className={classes.controlsGroup}>
                    <Can
                        role={user.profile}
                        perform="tickets-manager:showall"
                        yes={() => (
                            <div className={classes.switchContainer}>
                                <Tooltip title={i18n.t("tickets.buttons.mine")}>
                                    <User size={24} className={classes.iconButton} />
                                </Tooltip>
                                <Switch
                                    size="small"
                                    checked={showAllTickets}
                                    onChange={() => setShowAllTickets(prev => !prev)}
                                    color="primary"
                                />
                                <Tooltip title={i18n.t("tickets.buttons.showAll")}>
                                    <UsersFour size={24} className={classes.iconButton} />
                                </Tooltip>
                            </div>
                        )}
                    />
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <TicketsQueueSelect
                        selectedQueueIds={selectedQueueIds}
                        userQueues={user?.queues}
                        onChange={setSelectedQueueIds}
                    />
                </div>
            </div>

            <div className={classes.contentArea}>
                <TabPanel value={tab} name="open">
                    <div className={classes.ticketlist}>
                        <TicketsList
                            status="open"
                            showAll={showAllTickets}
                            selectedQueueIds={selectedQueueIds}
                            updateCount={setOpenCount}
                            style={applyPanelStyle("open")}
                        />
                        <TicketsList
                            status="pending"
                            selectedQueueIds={selectedQueueIds}
                            updateCount={setPendingCount}
                            style={applyPanelStyle("pending")}
                        />
                    </div>
                </TabPanel>
                <TabPanel value={tab} name="pending">
                    <div className={classes.ticketlist}>
                        <TicketsList
                            status="pending"
                            showAll={true}
                            selectedQueueIds={selectedQueueIds}
                        />
                    </div>
                </TabPanel>
                <TabPanel value={tab} name="closed">
                    <div className={classes.ticketlist}>
                        <TicketsList
                            status="closed"
                            showAll={true}
                            selectedQueueIds={selectedQueueIds}
                        />
                    </div>
                </TabPanel>
                <TabPanel value={tab} name="search">
                    <div className={classes.ticketlist}>
                        <TicketsList
                            searchParam={searchParam}
                            showAll={true}
                            selectedQueueIds={selectedQueueIds}
                        />
                    </div>
                </TabPanel>
            </div>
        </Paper>
    );
};

export default TicketsManager;
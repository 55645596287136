import React, { useState, useContext } from "react";
import {
  Button,
  TextField,
  Grid,
  Typography,
  InputAdornment,
  IconButton,
  Link,
  CircularProgress,
} from "@material-ui/core";
import { Eye, EyeSlash } from "phosphor-react";
import { makeStyles } from "@material-ui/core/styles";
import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import { system } from "../../config.json";
import logo from "../../assets/main-logo.webp"; 
import back from "../../assets/back.webp"; 

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      <Link color="inherit" href={system.url || "https://wasap.com.br"}>
        {system.name}
      </Link>
      {", uma solução MeuHub "}
      © {new Date().getFullYear()}
    </Typography>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end", 
    backgroundImage: `url(${back})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  glassContainer: {
    backgroundColor: "rgba(255, 255, 255, 0.55)",
    backdropFilter: "blur(10px)",
    padding: theme.spacing(4),
    paddingLeft: "50px",
    paddingRight: "50px",
    boxShadow: "0 8px 32px 0 rgba(0, 0, 0, 0.37)", 
    border: "1px solid rgba(255, 255, 255, 0.18)",
    width: "100%",
    maxWidth: 600,
    height: "100%", 
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center", 
  },
  logo: {
    width: "auto",
    height: "100px",
    marginBottom: theme.spacing(2),
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(1.5),
    backgroundColor: "#4d04a4",
    borderRadius: 13,
    "&:hover": {
      backgroundColor: "#2c025e",
    },
  },
  input: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "rgba(255, 255, 255, 0.9)",
      borderRadius: 13,
      "& fieldset": {
        borderRadius: 13,
      }
    },
    "& input:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset",
      WebkitTextFillColor: "rgba(0, 0, 0, 0.87)",
      backgroundColor: "white !important",
      backgroundClip: "content-box !important"
    },
    "& input:-webkit-autofill:focus": {
      WebkitBoxShadow: "0 0 0 1000px white inset"
    }
  },
  helpLink: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  copyright: {
    marginTop: theme.spacing(3),
    color: "rgba(0, 0, 0, 0.7)",
  }
}));

const Login = () => {
  const classes = useStyles();
  const { handleLogin, loading, error } = useContext(AuthContext);

  const [user, setUser] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);

  const handleChangeInput = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin(user);
  };

  return (
    <div className={classes.root}>
      <div className={classes.glassContainer}>
        <img src={logo} alt="Logo" className={classes.logo} />
        
        <Typography component="h1" variant="h5">
          {i18n.t("login.title")}
        </Typography>

        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={i18n.t("login.form.email")}
            name="email"
            autoComplete="email"
            autoFocus
            value={user.email}
            onChange={handleChangeInput}
            className={classes.input}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={i18n.t("login.form.password")}
            type={showPassword ? "text" : "password"}
            id="password"
            value={user.password}
            onChange={handleChangeInput}
            autoComplete="current-password"
            className={classes.input}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword((show) => !show)}
                  >
                    {showPassword ? <EyeSlash /> : <Eye />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {error && (
            <Typography color="error" variant="body2">
              {error}
            </Typography>
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loading}
          >
            {i18n.t("login.buttons.submit")}
            {loading && (
              <CircularProgress size={24} style={{ marginLeft: 8 }} />
            )}
          </Button>

          <Grid container className={classes.helpLink}>
            <Grid item xs>
              <Link
                href="https://api.whatsapp.com/send?phone=5554981346623"
                variant="body2"
                target="_blank"
                rel="noopener"
              >
                Preciso de Ajuda
              </Link>
            </Grid>
          </Grid>

          <div className={classes.copyright}>
            <Copyright />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
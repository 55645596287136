import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";

import {
    AppBar,
    Divider,
    Drawer,
    IconButton,
    Link,
    List,
    makeStyles,
    Menu,
    MenuItem,
    Toolbar,
    Typography
} from "@material-ui/core";

import { List as ListIcon, CaretLeft, CaretRight, UserCircle, SignOut, Moon, Sun, Bell } from "phosphor-react";
import miniLogo from "../assets/mini-logo-wt.webp"; 

import MainListItems from "./MainListItems";
import NotificationsPopOver from "../components/NotificationsPopOver";
import UserModal from "../components/UserModal";
import { AuthContext } from "../context/Auth/AuthContext";
import BackdropLoading from "../components/BackdropLoading";
import { i18n } from "../translate/i18n";

import api from "../services/api";
import toastError from "../errors/toastError";
import { system } from "../config.json";
import { systemVersion } from "../../package.json";
import logodash from "../assets/main-logo.webp";
import { useGreeting } from '../hooks/useGreeting';
import TrialDays from "../components/TrialDays";
import { toast } from "react-toastify";

const drawerWidth = 280; 

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        height: "100vh",
        backgroundColor: theme.palette.background.default,
        [theme.breakpoints.down("sm")]: {
            height: "calc(100vh - 56px)",
        },
    },
    toolbar: {
        paddingRight: 24,
        color: "#ffffff",
        background: `linear-gradient(0deg, ${theme.palette.toolbar.main} 10%, ${theme.palette.primary.light} 100%)`,
        backdropFilter: "blur(8px)",
        boxShadow: "0 2px 15px rgba(0,0,0,0.1)",
        height: 64,
        "& .MuiIconButton-root": { 
            borderRadius: 40,
            padding: theme.spacing(1), 
            height: 40, 
        }
    },
    toolbarIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 16px",
        minHeight: "55px",
        backgroundColor: theme.palette.toolbarIcon.main,
        "& img": {
            maxHeight: "40px",
            maxWidth: "180px",
            transition: "all 0.3s ease",
            "&:hover": {
                transform: "scale(1.05)",
            }
        }
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: "all 0.3s ease",
        backdropFilter: "blur(8px)",
        backgroundColor: "rgba(255,255,255,0.8)",
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: "all 0.3s ease",
    },
    menuButton: {
        marginRight: 36,
        transition: "all 0.2s ease",
        display: "flex",
        alignItems: "center",
        borderRadius: 8, 
        padding: theme.spacing(1), 
        "&:hover": {
            transform: "scale(1.05)", 
        }
    },
    menuButtonHidden: {
        display: "none",
    },
    title: {
        flexGrow: 1,
        fontSize: "1.1rem",
        fontWeight: 500,
        letterSpacing: "0.5px",
    },
    drawerPaper: {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: "all 0.3s ease",
        backgroundColor: theme.palette.background.paper,
        borderRight: "none",
        boxShadow: "2px 0 15px rgba(0,0,0,0.08)",
    },
    drawerPaperClose: {
        overflowX: "hidden",
        transition: "all 0.3s ease",
        width: theme.spacing(9),
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: {
        minHeight: "55px",
    },
    content: {
        flex: 1,
        overflow: "auto",
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        transition: "all 0.3s ease",
    },
    container: {
        padding: theme.spacing(4),
        borderRadius: 16,
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0 4px 20px rgba(0,0,0,0.05)",
    },
    paper: {
        padding: theme.spacing(3),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        borderRadius: 12,
        border: `1px solid ${theme.palette.divider}`,
        transition: "all 0.3s ease",
        "&:hover": {
            boxShadow: "0 8px 25px rgba(0,0,0,0.05)",
            transform: "translateY(-2px)",
        }
    },
    systemCss: {
        display: "flex",
        justifyContent: "center",
        opacity: 0.4,
        fontSize: 12,
        marginTop: theme.spacing(2),
    },
    userMenu: {
        marginLeft: theme.spacing(2),
        "& .MuiIconButton-root": { 
            borderRadius: 40,
            padding: theme.spacing(1),
            height: 40,
        }
    },
}));

const LoggedInLayout = ({ children }) => {
    const classes = useStyles();
    const [userModalOpen, setUserModalOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const { handleLogout, loading, user } = useContext(AuthContext);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerVariant, setDrawerVariant] = useState("permanent");
    const greeting = useGreeting();
    const [theme, setTheme] = useState(() => localStorage.getItem("theme") || "light");

    const handleThemeChange = () => {
        const newTheme = theme === "light" ? "dark" : "light";
        setTheme(newTheme);
        localStorage.setItem("theme", newTheme);
        window.location.reload();
    };

    useEffect(() => {

        if (document.body.offsetWidth > 600) {
            const fetchDrawerState = async () => {
                try {
                    const { data } = await api.get("/settings");

                    const settingIndex = data.filter(s => s.key === 'sideMenu');

                    setDrawerOpen(settingIndex[0].value === "disabled" ? false : true);

                } catch (err) {
                    setDrawerOpen(true);
                    toastError(err);
                }
            };
            fetchDrawerState();
        }
    }, []);

    useEffect(() => {
        if (document.body.offsetWidth < 600) {
            setDrawerVariant("temporary");
        } else {
            setDrawerVariant("permanent");
        }
    }, [drawerOpen]);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
        setMenuOpen(false);
    };

    const handleOpenUserModal = () => {
        setUserModalOpen(true);
        handleCloseMenu();
    };

    const handleClickLogout = () => {
        handleCloseMenu();
        handleLogout();
    };

    const drawerClose = () => {
        if (document.body.offsetWidth < 600) {
            setDrawerOpen(false);
        }
    };

    if (loading) {
        return <BackdropLoading />;
    }

    return (
        <div className={classes.root}>
            <Drawer
                variant={drawerVariant}
                className={drawerOpen ? classes.drawerPaper : classes.drawerPaperClose}
                classes={{
                    paper: clsx(
                        classes.drawerPaper,
                        !drawerOpen && classes.drawerPaperClose
                    ),
                }}
                open={drawerOpen}
            >
                <div className={classes.toolbarIcon}>
                    <img src={logodash} alt="logo" />
                    <IconButton color="secondary" onClick={() => setDrawerOpen(!drawerOpen)}>
                        <CaretLeft size={26} />
                    </IconButton>
                </div>
                <List>
                    <MainListItems drawerClose={drawerClose} />
                </List>
            </Drawer>
            <UserModal
                open={userModalOpen}
                onClose={() => setUserModalOpen(false)}
                userId={user?.id}
            />
            <AppBar
                position="absolute"
                className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}
                color={process.env.NODE_ENV === "development" ? "inherit" : "primary"}
            >
                <Toolbar variant="dense" className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => setDrawerOpen(!drawerOpen)}
                        className={clsx(
                            classes.menuButton,
                            drawerOpen && classes.menuButtonHidden
                        )}
                    >
                        {drawerOpen ? (
                            <ListIcon size={26} />
                        ) : (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img 
                                    src={miniLogo} 
                                    alt="mini logo" 
                                    style={{ 
                                        height: '32px',
                                        width: 'auto',
                                        marginRight: '5px'
                                    }} 
                                />
                                <CaretRight size={26} />
                            </div>
                        )}
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        className={classes.title}
                    >
                        {greeting}, {user.name}!
                    </Typography>
                    
                    <TrialDays />
                    
                    {user.id && <NotificationsPopOver />}

                    <div className={classes.userMenu}>
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <UserCircle size={24} /> 
                        </IconButton>
                        <MenuComponent
                            anchorEl={anchorEl}
                            handleCloseMenu={handleCloseMenu}
                            menuOpen={menuOpen}
                            handleOpenUserModal={handleOpenUserModal}
                            handleClickLogout={handleClickLogout}
                            systemVersion={systemVersion}
                            system={system}
                            handleThemeChange={handleThemeChange}
                            theme={theme}
                        />
                    </div>
                </Toolbar>
            </AppBar>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />

                {children ? children : null}
            </main>
        </div>
    );
};

const MenuComponent = ({ anchorEl, handleCloseMenu, menuOpen, handleOpenUserModal, handleClickLogout, systemVersion, system, handleThemeChange, theme }) => {
    const { user } = useContext(AuthContext);

    const handleRequestNotificationPermission = () => {
        if (!("Notification" in window)) {
            toast.error(i18n.t("mainDrawer.appBar.notifications.unsupported"));
            handleCloseMenu();
            return;
        }
      

        if (Notification.permission === "denied") {
            toast.error(
              i18n.t("mainDrawer.appBar.notifications.blocked"), 
              {
                autoClose: 8000,
                onClick: () => {

                  if (navigator.permissions) {
                    navigator.permissions.query({ name: 'notifications' }).then(result => {
                      if (result.state === "denied") {

                        window.open('chrome://settings/content/notifications');

                        window.open('about:preferences#privacy');
                      }
                    });
                  }
                }
              }
            );
        } else {

            Notification.requestPermission().then(permission => {
              switch(permission) {
                case "granted":
                  toast.success(i18n.t("mainDrawer.appBar.notifications.success"), {
                    autoClose: 3000
                  });
                  break;
                case "denied": 
                  toast.error(i18n.t("mainDrawer.appBar.notifications.denied"), {
                    autoClose: 5000
                  });
                  break;
                default:
                  toast.warning(i18n.t("mainDrawer.appBar.notifications.notGranted"), {
                    autoClose: 5000
                  });
              }
            });
        }
      
        handleCloseMenu();
    };

    return (
        <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={menuOpen}
            onClose={handleCloseMenu}
            PaperProps={{
                style: {
                    borderRadius: 12,
                    boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
                    marginTop: 8,
                }
            }}
        >
            <MenuItem onClick={handleOpenUserModal} style={{ padding: "12px 24px" }}>
                <UserCircle size={26} style={{ marginRight: 12 }} />
                {i18n.t("mainDrawer.appBar.user.profile")}
            </MenuItem>
            <MenuItem onClick={handleThemeChange} style={{ padding: "12px 24px" }}>
                {theme === "light" ? (
                    <>
                        <Moon size={26} style={{ marginRight: 12 }} />
                        {i18n.t("mainDrawer.appBar.theme.dark")}
                    </>
                ) : (
                    <>
                        <Sun size={26} style={{ marginRight: 12 }} /> 
                        {i18n.t("mainDrawer.appBar.theme.light")}
                    </>
                )}
            </MenuItem>
            <MenuItem onClick={handleRequestNotificationPermission} style={{ padding: "12px 24px" }}>
                <Bell size={26} style={{ marginRight: 12 }} />
                {i18n.t("mainDrawer.appBar.user.permissions")}
            </MenuItem>
            <MenuItem onClick={handleClickLogout} style={{ padding: "12px 24px" }}>
                <SignOut size={26} style={{ marginRight: 12 }} />
                {i18n.t("mainDrawer.appBar.user.logout")}
            </MenuItem>
            <Divider style={{ margin: "8px 0" }} />
            <MenuItem disabled style={{ justifyContent: "center", padding: "8px 24px" }}>
                <Link 
                    color="inherit" 
                    href={system.url || "https://wasap.com.br"}
                    style={{ textDecoration: "none" }}
                >
                    <Typography variant="caption">
                        Versão {systemVersion}
                    </Typography>
                </Link>
            </MenuItem>
        </Menu>
    );
};
export default LoggedInLayout;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    flex: 1,
    padding: 0,
    width: "100%",
    position: "relative",
    transition: "all 0.3s ease",
    overflow: "hidden", // Impede overflow na container principal
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 56px)", // Ajuste para mobile
    }
  },

  contentWrapper: {
    height: "100%", 
    display: "flex",
    flexDirection: "column",
    position: "relative",
    overflow: "hidden", // Impede overflow horizontal
    overflowY: "auto", // Permite apenas scroll vertical quando necessário
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    transition: "all 0.3s ease",
    margin: 0, // Remove margens que podem causar overflow
    padding: theme.spacing(1), // Adiciona padding interno para espaçamento
    boxSizing: "border-box", // Garante que padding não cause overflow

    "&::-webkit-scrollbar": {
      width: "8px",
      height: "0px", // Remove a barra de rolagem horizontal
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.primary.light,
      borderRadius: "4px",
    }
  }
}));

const MainContainer = ({ children }) => {
  const classes = useStyles();

  return (
    <Container 
      className={classes.mainContainer}
      maxWidth={false}
      disableGutters // Remove padding lateral
    >
      <div className={classes.contentWrapper}>
        {children}
      </div>
    </Container>
  );
};

export default MainContainer;

import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import {
  Button,
  Drawer,
  DialogContent,
  CircularProgress,
  Select,
  InputLabel,
  makeStyles,
  MenuItem,
  FormControl,
  TextField,
  InputAdornment,
  IconButton,
  Typography
} from '@material-ui/core';

import { Visibility, VisibilityOff } from '@material-ui/icons';
import { green } from "@material-ui/core/colors";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import useWhatsApps from "../../hooks/useWhatsApps";
import UserAvatar from '../UserAvatar'; 

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 400,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  drawerHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(3)
  },
  drawerTitle: {
    fontWeight: 600,
    fontSize: '1.4rem',
    textAlign: 'center'
  },
  btnWrapper: {
    position: "relative",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
    gap: theme.spacing(1)
  },
  button: {
    marginTop: 16,
    borderRadius: 13
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1, 0),
    minWidth: 120,
  },
  textField: {
    marginBottom: theme.spacing(2),
    width: '100%'
  }
}));

const UserSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  password: Yup.string().min(5, "Too Short!").max(50, "Too Long!"),
  email: Yup.string().email("Invalid email").required("Required"),
});

const UserModal = ({ open, onClose, userId, onSave }) => {

  const classes = useStyles();

  const initialState = {
    name: "",
    email: "",
    password: "",
    profile: "user",
    startWork: "",
    endWork: "",
    isTricked: "enabled"
  };

  const { user: loggedInUser } = useContext(AuthContext);
  const [user, setUser] = useState(initialState);
  const [selectedQueueIds, setSelectedQueueIds] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [whatsappId, setWhatsappId] = useState(false);
  const { loading, whatsApps } = useWhatsApps();
  const startWorkRef = useRef();
  const endWorkRef = useRef();
  const history = useHistory();

  useEffect(() => {
    const fetchUser = async () => {
      if (!userId) return;
      try {
        const { data } = await api.get(`/users/${userId}`);
        setUser({
          ...initialState,
          ...data,
          avatar: data.avatar || null
        });
        const userQueueIds = data.queues?.map(queue => queue.id);
        setSelectedQueueIds(userQueueIds);
        setWhatsappId(data.whatsappId ? data.whatsappId : '');
      } catch (err) {
        toastError(err);
      }
    };
    fetchUser();
  }, [userId, open]);

  useEffect(() => {
    if (userId && user && user.avatar) {
      console.log("Avatar do usuário atualizado:", user.avatar);
    }
  }, [userId, user?.avatar]);

  const handleClose = () => {
    onClose();
    setUser(initialState);
  };

  const handleSaveUser = async values => {
    try {
      if (userId) {
        await api.put(`/users/${userId}`, { ...values });
      } else {
        await api.post("/users", { ...values });
      }
      toast.success(i18n.t("userModal.success.edit")); 
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      classes={{
        paper: classes.drawer
      }}
    >
      <div className={classes.drawerHeader}>
        <Typography variant="h6" className={classes.drawerTitle}>
          {userId
            ? `${i18n.t("userModal.title.edit")}`
            : `${i18n.t("userModal.title.add")}`}
        </Typography>
      </div>
      
      <Formik
        initialValues={user}
        enableReinitialize={true}
        validationSchema={UserSchema}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            handleSaveUser(values);
            actions.setSubmitting(false);
          }, 400);
        }}
      >
        {({ touched, errors, isSubmitting }) => (
          <Form>
            <DialogContent>

              {userId && (
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 16 }}>
                  <UserAvatar 
                    user={user} 
                    setUser={setUser} 
                    size={80} 
                    key={`avatar-${userId}-${user.avatar || 'default'}`} 
                  />
                </div>
              )}

              <Field
                as={TextField}
                label={i18n.t("userModal.form.name")}
                name="name"
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
                variant="outlined"
                className={classes.textField}
                fullWidth
              />

              <Field
                as={TextField}
                name="password"
                variant="outlined"
                label={i18n.t("userModal.form.password")}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((e) => !e)}
                      >
                        {showPassword ? <VisibilityOff color="secondary" /> : <Visibility color="secondary" />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                className={classes.textField}
                fullWidth
              />

              <Field
                as={TextField}
                label={i18n.t("userModal.form.email")}
                name="email"
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                variant="outlined"
                className={classes.textField}
                fullWidth
              />

              <Can
                role={loggedInUser.profile}
                perform="user-modal:editProfile"
                yes={() => (
                  <FormControl
                    variant="outlined"
                    className={classes.textField}
                    fullWidth
                  >
                    <InputLabel>
                      {i18n.t("userModal.form.profile")}
                    </InputLabel>
                    <Field
                      as={Select}
                      label={i18n.t("userModal.form.profile")}
                      name="profile"
                      required
                    >
                      <MenuItem value="admin">{i18n.t("userModal.form.admin")}</MenuItem>
                      <MenuItem value="user">{i18n.t("userModal.form.user")}</MenuItem>
                    </Field>
                  </FormControl>
                )}
              />

              <Can
                role={loggedInUser.profile}
                perform="user-modal:editQueues"
                yes={() => (
                  <>

                    {!loading && (
                      <FormControl 
                        variant="outlined" 
                        className={classes.textField}
                        fullWidth
                      >
                        <InputLabel>{i18n.t("userModal.form.whatsapp")}</InputLabel>
                        <Field
                          as={Select}
                          value={whatsappId}
                          onChange={(e) => setWhatsappId(e.target.value)}
                          label={i18n.t("userModal.form.whatsapp")}
                        >
                          <MenuItem value={''}>&nbsp;</MenuItem>
                          {whatsApps.map((whatsapp) => (
                            <MenuItem key={whatsapp.id} value={whatsapp.id}>
                              {whatsapp.name}
                            </MenuItem>
                          ))}
                        </Field>
                        <QueueSelect
                         selectedQueueIds={selectedQueueIds}
                         onChange={values => setSelectedQueueIds(values)}
                       />
                      </FormControl>
                    )}
                  </>
                )}
              />

              <Can
                role={loggedInUser.profile}
                perform="user-modal:editProfile"
                yes={() => (!loading &&
                  <>
                    <Field
                      as={TextField}
                      label={i18n.t("userModal.form.startWork")}
                      type="time"
                      ampm={false}
                      defaultValue="00:00"
                      inputRef={startWorkRef}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 600,
                      }}
                      name="startWork"
                      error={touched.startWork && Boolean(errors.startWork)}
                      helperText={touched.startWork && errors.startWork}
                      variant="outlined"
                      className={classes.textField}
                      fullWidth
                    />

                    <Field
                      as={TextField}
                      label={i18n.t("userModal.form.endWork")}
                      type="time" 
                      ampm={false}
                      defaultValue="23:59"
                      inputRef={endWorkRef}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 600,
                      }}
                      name="endWork"
                      error={touched.endWork && Boolean(errors.endWork)}
                      helperText={touched.endWork && errors.endWork}
                      variant="outlined"
                      className={classes.textField}
                      fullWidth
                    />
                  </>
                )}
              />

              <Can
                role={loggedInUser.profile}
                perform="user-modal:editProfile"
                yes={() => (
                  <FormControl
                    variant="outlined"
                    className={classes.textField}
                    fullWidth
                  >
                    <InputLabel>
                      {i18n.t("userModal.form.isTricked")}
                    </InputLabel>
                    <Field
                      as={Select}
                      label={i18n.t("userModal.form.isTricked")}
                      name="isTricked"
                    >
                      <MenuItem value="enabled">{i18n.t("userModal.form.enabled")}</MenuItem>
                      <MenuItem value="disabled">{i18n.t("userModal.form.disabled")}</MenuItem>
                    </Field>
                  </FormControl>
                )}
              />
            </DialogContent>

            <div className={classes.btnWrapper}>
              <Button
                onClick={handleClose}
                color="secondary"
                disabled={isSubmitting}
                variant="outlined"
                className={classes.button}
              >
                {i18n.t("userModal.buttons.cancel")}
              </Button>
              <Button
                type="submit"
                color="primary"
                disabled={isSubmitting}
                variant="contained"
                className={classes.button}
              >
                {userId
                  ? `${i18n.t("userModal.buttons.okEdit")}`
                  : `${i18n.t("userModal.buttons.okAdd")}`}
                {isSubmitting && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};

export default UserModal;
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar as MUIAvatar } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  avatar: {
    width: props => props.size,
    height: props => props.size,
    fontSize: props => props.size / 2,
    backgroundColor: theme.palette.primary.main
  }
}));

const UserAvatar = ({ user, size = 40, className = '' }) => {
  const classes = useStyles({ size });

  const getInitials = (name) => {
    return name
      .split(' ')
      .map(part => part[0])
      .slice(0, 2)
      .join('')
      .toUpperCase();
  };

  return (
    <MUIAvatar 
      className={`${classes.avatar} ${className}`}
      alt={user.name}
    >
      {getInitials(user.name)}
    </MUIAvatar>
  );
};

UserAvatar.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  size: PropTypes.number,
  className: PropTypes.string,
};

export default UserAvatar;
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Paper, Button } from '@material-ui/core';
import { WarningCircle } from 'phosphor-react';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default
  },
  paper: {
    padding: theme.spacing(6),
    textAlign: 'center',
    borderRadius: 16,
    maxWidth: 600
  },
  icon: {
    fontSize: 84,
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2)
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: 600,
    color: theme.palette.primary.main
  },
  message: {
    marginBottom: theme.spacing(3),
    color: theme.palette.text.secondary
  },
  button: {
    borderRadius: 8,
    padding: '10px 24px',
    textTransform: 'none'
  }
}));

const UnavailablePage = () => {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Paper elevation={0} className={classes.paper}>
        <WarningCircle size={84} weight="fill" className={classes.icon} />
        
        <Typography variant="h4" className={classes.title}>
          Sistema Indisponível
        </Typography>

        <Typography variant="body1" className={classes.message}>
          Por favor, entre em contato com o suporte para mais informações.
        </Typography>

        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          href="https://api.whatsapp.com/send?phone=5554981346623"
        >
          Contatar Suporte
        </Button>
      </Paper>
    </Container>
  );
};

export default UnavailablePage;
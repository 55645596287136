import React from "react";
import { Tabs, Tab, Paper, makeStyles } from "@material-ui/core";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
  tabsContainer: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 13,
    padding: "8px 8px",
    marginBottom: theme.spacing(2),
    position: "sticky",
    top: 0,
    zIndex: 999,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100vw',
    }
  },
  tabs: {
    display: 'flex',
    borderBottom: "none",
    "& .MuiTabs-flexContainer": {
      justifyContent: "center",
      [theme.breakpoints.down('sm')]: {
        justifyContent: "flex-start",
      }
    },
    "& .MuiTab-root": {
      minWidth: 40,
      minHeight: 40,
      padding: "6px 12px",
      borderRadius: 8,
      fontSize: "0.9rem",
      transition: "all 0.3s ease",
      [theme.breakpoints.down('sm')]: {
        padding: "6px 8px",
        minWidth: 32,
      }
    },
    "& .Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
      borderRadius: 8,
      fontWeight: 600,
    },
    "& .MuiTabs-indicator": {
      display: "none"
    }
  },
  scrollButtons: {
    color: theme.palette.primary.main
  }
}));

const AlphabetTabs = ({ currentTab, onChange }) => {
  const classes = useStyles();
  const tabs = [
    { value: "all", label: i18n.t("contacts.tabs.all") },
    ...Array.from(Array(26), (_, i) => ({ 
      value: String.fromCharCode(65 + i),
      label: String.fromCharCode(65 + i)
    })),
    { value: "0-9", label: i18n.t("contacts.tabs.numbers") }
  ];

  return (
    <Paper className={classes.tabsContainer} elevation={0}>
      <Tabs
        value={currentTab}
        onChange={(e, newValue) => onChange(newValue)}
        variant="scrollable"
        scrollButtons="auto"
        className={classes.tabs}
        classes={{
          scrollButtons: classes.scrollButtons
        }}
      >
        {tabs.map((tab) => (
          <Tab 
            key={tab.value}
            value={tab.value}
            label={tab.label}
            disableRipple
          />
        ))}
      </Tabs>
    </Paper>
  );
};

export default AlphabetTabs;